<template>
  <div class="tabs">
    <ul ref="list" role="listbox" class="tabs__list">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{
          tabs__item: true,
          'tabs__item--active': index === activeIndex,
        }"
        @click="handleSelect(index)"
      >
        {{ tab }}
      </li>
    </ul>
  </div>
</template>

<script>
import ease from "easy-ease";

export default {
  name: "TabsGallery",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    tabsPersons: {
      type: Array,
      required: true,
    },
    defaultActive: {
      type: Number,
      default: 0,
    },
    settings: {
      type: Object,
      default() {
        return {
          centredTabMobile: false,
        };
      },
    },
    value: {},
  },
  data: () => ({
    activeIndex: null,
  }),
  watch: {
    activeIndex() {
      this.showTab(this.activeIndex);
    },
    value() {
      this.activeIndex = this.value;
    },
  },
  mounted() {
    this.activeIndex = this.defaultActive;
  },
  methods: {
    getTabsWidth() {
      return this.$refs.list.clientWidth;
    },
    getTabsScroll() {
      return this.$refs.list.scrollLeft;
    },
    getWidthBefore(index) {
      let width = 0;
      let elIndex = 0;
      for (const el of this.$refs.list.children) {
        if (index === elIndex) {
          break;
        }

        width += el.offsetWidth;
        elIndex++;
      }
      return width;
    },
    isTabVisible(index) {
      const item = this.$refs.list.children[index];
      if (this.getWidthBefore(index) - this.getTabsScroll() < 0) {
        return false;
      }

      return (
        this.getWidthBefore(index) + item.offsetWidth - this.getTabsScroll() <=
        this.getTabsWidth()
      );
    },
    showTab(index) {
      const item = this.$refs.list.children[index];
      let endValue = 0;

      if (this.settings.centredTabMobile) {
        endValue =
          this.getWidthBefore(index) +
          item.offsetWidth +
          (this.getTabsWidth() - item.offsetWidth) / 2 -
          this.getTabsWidth();
      } else {
        if (!this.isTabVisible(index)) {
          endValue =
            this.getWidthBefore(index) + item.offsetWidth - this.getTabsWidth();
        }
      }

      if (this.getWidthBefore(item) - this.getTabsScroll() < 0) {
        endValue = this.getWidthBefore(item);
      }

      ease({
        durationMs: 300,
        startValue: this.$refs.list.scrollLeft,
        endValue: endValue,
        // onStep: value => this.$refs.list.scrollLeft = value
        onStep: function (value) {
          if (!this.$refs) return;
          this.$refs.list.scrollLeft = value;
        },
      });
    },
    handleSelect(index) {
      this.activeIndex = index;
      this.$emit("input", this.activeIndex);
      this.$emit("change", this.activeIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  background-color: #ffffff;
  display: inline-flex;
  box-shadow: 8px 6px 15px rgba(0, 0, 0, 0.07);

  &__list {
    display: flex;
    flex: 1;
    box-shadow: inset 0 0 0 1px rgba(24, 24, 27, 0.03);
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    list-style-type: none;
    padding: 14px 10px 10px;
    cursor: pointer;
    position: relative;
    transition: all 300ms ease-out;
    display: flex;
    justify-content: center;
    color: #18181b;
    font-family: $font-family-bebas;
    font-style: normal;
    font-weight: bold;
    line-height: 132.5%;
    letter-spacing: 0.18em;
    font-size: 9px;
    @media screen and (min-width: $screen-sm) {
      padding: 14px 15px 10px;
      font-size: 14px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }

    &:before {
      display: block;
      content: " ";
      position: absolute;
      height: 3px;
      width: 100%;
      background: #e14413;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: all 300ms ease-out;
    }

    &:hover {
      color: #e14313;
    }

    &:after {
      display: block;
      content: " ";
      background-color: rgb(243, 243, 243);
      position: absolute;
      right: 0;
      height: 18px;
      top: 12px;
      width: 1px;
    }

    &:last-child {
      border-right: 0;
    }

    &:hover {
      opacity: 0.7;
    }

    &--active {
      color: #e14313;

      &:before {
        opacity: 1;
      }
    }
  }
}
</style>
