var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "28",
        height: "27",
        viewBox: "0 0 28 27",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          opacity: "0.2",
          d: "M6.232 0.199993H10.984L7.384 12.872H11.56V26.84H0.76V13.88L6.232 0.199993ZM22.36 0.199993H26.968L23.512 12.872H27.544V26.84H16.888V13.88L22.36 0.199993Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }