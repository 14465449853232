var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-gallery-block" },
    [
      _c(
        "content-wrapper-block",
        { attrs: { "no-overflow": "" } },
        [
          !_vm.hidePageTitle
            ? _c("page-title", [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "image-gallery-block__tabs gallery-tabs" },
            [
              _c("tabs-gallery", {
                ref: "tabs",
                attrs: {
                  tabs: _vm.tabs,
                  "tabs-persons": _vm.personsGalleryState,
                },
                on: { change: _vm.handleTabChange },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "image-gallery-block__images" }, [
            _c(
              "div",
              { staticClass: "images-slider__block" },
              [
                _c(
                  "swiper",
                  {
                    ref: "swiper",
                    attrs: {
                      options: {
                        loop: true,
                        slidesPerView: "auto",
                        spaceBetween: 20,
                        pagination: {
                          el: ".swiper-pagination",
                          type: "fraction",
                          renderFraction: _vm.renderFraction,
                          formatFractionCurrent: _vm.formatFractionCurrent,
                        },
                        breakpoints: {
                          320: {
                            slidesOffsetBefore: 30,
                          },
                          375: {
                            slidesOffsetBefore: 80,
                          },
                          640: {
                            slidesOffsetBefore: 150,
                          },
                        },
                      },
                    },
                    on: { "slide-change": _vm.slideChange },
                  },
                  [
                    _vm._l(_vm.imageGalleryState, function (item, i) {
                      return _c(
                        "swiper-slide",
                        {
                          key: i,
                          ref: "slide",
                          refInFor: true,
                          staticClass: "image-gallery-block__slide",
                        },
                        [
                          item.category === "дизайнерская отделка"
                            ? _c("video", {
                                staticClass: "image-gallery-block__slide-image",
                                attrs: { src: item.video, controls: "" },
                              })
                            : _vm._e(),
                          item.image
                            ? _c(
                                "picture",
                                {
                                  staticClass: "image-gallery-block__slide-pic",
                                },
                                [
                                  item.image.source
                                    ? _c("source", {
                                        attrs: {
                                          srcset: item.image.source,
                                          media: "(min-width: 1024px)",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("img", {
                                    staticClass:
                                      "image-gallery-block__slide-image",
                                    attrs: {
                                      src:
                                        item.image.mobile || item.image.source,
                                      alt: item.category,
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    _c("div", {
                      ref: "test",
                      staticClass: "swiper-pagination",
                      attrs: { slot: "pagination" },
                      slot: "pagination",
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "image-gallery-block__footer" },
            [
              _c("managers-comment", {
                ref: "manager",
                attrs: { "persons-gallery-state": _vm.personsGalleryState },
              }),
              _c(
                "div",
                { staticClass: "image-gallery-block__navigation navigation" },
                [
                  _c("slider-navigation-arrows", {
                    attrs: {
                      "active-next": _vm.svipeNext,
                      "active-prev": _vm.svipePrev,
                      "disable-prev": _vm.disableButtonPrev,
                      "disable-next": _vm.disablButtonNext,
                    },
                    on: { next: _vm.goToNext, prev: _vm.goToPrev },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }