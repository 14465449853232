<template>
  <div>
    <div
      v-for="(person, index) in personsGalleryState"
      :key="index"
      :class="{ comment: true, comment__active: index === activeIndex }"
    >
      <div class="comment__avatar">
        <img :src="person.avatar" class="comment__avatar-image" />
      </div>
      <div class="comment__info">
        <div class="comment__name">
          {{ person.name }}
        </div>
        <div class="comment__position">
          {{ person.position }}
        </div>
      </div>
      <div class="comment__message">
        <icon-quote class="comment__message-quote" />
        <div class="comment__message-text">
          {{ person.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconQuote from "@/components/icons/IconQuote";

export default {
  name: "ManagersComment",
  components: {
    IconQuote,
  },
  props: {
    personsGalleryState: {
      type: Array,
      required: true,
    },
    defaultActive: {
      type: Number,
      default: 0,
    },
    // value: {}
  },
  data: () => ({
    activeIndex: null,
  }),
  mounted() {
    this.activeIndex = this.defaultActive;
  },
  methods: {
    handleSelect(index) {
      this.activeIndex = index;
      // this.$emit('input', this.activeIndex)
      // this.$emit('change', this.activeIndex)
    },
  },
};
</script>

<style scoped lang="scss">
.comment {
  display: none;
  flex-direction: column;
  background-color: #221f20;
  min-height: 73px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  top: 0;
  max-width: 885px;

  @media screen and (min-width: $screen-mini) {
    top: -30px;
    flex-direction: row;
  }

  @media screen and (min-width: $screen-sm) {
    margin-right: 30px;
  }

  &__active {
    display: flex;
  }

  &__avatar {
    height: 61px;
    width: 61px;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: 9px 4px 9px rgba(0, 0, 0, 0.4);
    position: absolute;
    top: -40px;
    left: 0;

    @media screen and (min-width: $screen-mini) {
      top: -11px;
      left: -30px;
      height: 94px;
      width: 94px;
    }
  }

  &__avatar-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__info {
    margin-top: 8px;
    min-width: 160px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 0;
    padding-bottom: 25px;
    padding-left: 65px;
    margin-bottom: 25px;

    @media screen and (min-width: $screen-mini) {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 65px;
      padding-bottom: 0;
      padding-left: 0;
      border-bottom: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    letter-spacing: -0.03em;
  }

  &__position {
    font-weight: 300;
    font-size: 12px;
    opacity: 0.8;
  }

  &__message {
    display: flex;

    @media screen and (min-width: $screen-mini) {
      padding-left: 30px;
    }

    &-quote {
      margin-right: 15px;
      flex: 0 0 30px;
      position: absolute;
      top: 33px;
      left: 37px;

      @media screen and (min-width: $screen-mini) {
        position: unset;
        top: 0;
        left: 0;
      }
    }

    &-text {
      font-weight: 300;
      font-size: 14px;
    }
  }
}
</style>
