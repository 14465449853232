var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.personsGalleryState, function (person, index) {
      return _c(
        "div",
        {
          key: index,
          class: { comment: true, comment__active: index === _vm.activeIndex },
        },
        [
          _c("div", { staticClass: "comment__avatar" }, [
            _c("img", {
              staticClass: "comment__avatar-image",
              attrs: { src: person.avatar },
            }),
          ]),
          _c("div", { staticClass: "comment__info" }, [
            _c("div", { staticClass: "comment__name" }, [
              _vm._v(" " + _vm._s(person.name) + " "),
            ]),
            _c("div", { staticClass: "comment__position" }, [
              _vm._v(" " + _vm._s(person.position) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "comment__message" },
            [
              _c("icon-quote", { staticClass: "comment__message-quote" }),
              _c("div", { staticClass: "comment__message-text" }, [
                _vm._v(" " + _vm._s(person.message) + " "),
              ]),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }