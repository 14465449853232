<template>
  <div class="image-gallery-block">
    <content-wrapper-block no-overflow>
      <page-title v-if="!hidePageTitle">
        {{ pageTitle }}
      </page-title>
      <div class="image-gallery-block__tabs gallery-tabs">
        <tabs-gallery
          ref="tabs"
          :tabs="tabs"
          :tabs-persons="personsGalleryState"
          @change="handleTabChange"
        />
      </div>

      <div class="image-gallery-block__images">
        <div class="images-slider__block">
          <swiper
            ref="swiper"
            :options="{
              loop: true,
              slidesPerView: 'auto',
              spaceBetween: 20,
              pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
                renderFraction: renderFraction,
                formatFractionCurrent: formatFractionCurrent,
              },
              breakpoints: {
                320: {
                  slidesOffsetBefore: 30,
                },
                375: {
                  slidesOffsetBefore: 80,
                },
                640: {
                  slidesOffsetBefore: 150,
                },
              },
            }"
            @slide-change="slideChange"
          >
            <swiper-slide
              v-for="(item, i) in imageGalleryState"
              :key="i"
              ref="slide"
              class="image-gallery-block__slide"
            >
              <video
                v-if="item.category === 'дизайнерская отделка'"
                class="image-gallery-block__slide-image"
                :src="item.video"
                controls
              />
              <picture v-if="item.image" class="image-gallery-block__slide-pic">
                <source
                  v-if="item.image.source"
                  :srcset="item.image.source"
                  media="(min-width: 1024px)"
                />
                <img
                  class="image-gallery-block__slide-image"
                  :src="item.image.mobile || item.image.source"
                  :alt="item.category"
                />
              </picture>
            </swiper-slide>
            <div ref="test" slot="pagination" class="swiper-pagination" />
          </swiper>
        </div>
      </div>
      <div class="image-gallery-block__footer">
        <managers-comment
          ref="manager"
          :persons-gallery-state="personsGalleryState"
        />
        <div class="image-gallery-block__navigation navigation">
          <slider-navigation-arrows
            :active-next="svipeNext"
            :active-prev="svipePrev"
            :disable-prev="disableButtonPrev"
            :disable-next="disablButtonNext"
            @next="goToNext"
            @prev="goToPrev"
          />
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import TabsGallery from "@/components/elements/TabsGallery";
import ManagersComment from "@/components/elements/ManagersComment";
import SliderNavigationArrows from "@/components/ui/SliderNavigationArrows";

export default {
  name: "ImageGalleryBlock",
  components: {
    PageTitle,
    ContentWrapperBlock,
    TabsGallery,
    ManagersComment,
    SliderNavigationArrows,
  },
  provide: function () {
    return {
      addSlide: this.addSlide,
    };
  },
  props: {
    pageTitle: {
      type: String,
      default: "Галерея",
    },
    hidePageTitle: Boolean,
    loop: {
      type: Boolean,
      default: true,
    },
    debug: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    svipeNext: false,
    svipePrev: false,
    disableButtonPrev: false,
    disablButtonNext: false,
    managers: [],
    tabs: ["Архитектура", "Благоустройство", "Лобби", "Интерьер", "дизайнерская отделка"],
    slideIndex: null,
    tabIndex: null,
    managerIndex: null,
    currentSlide: 0,
    loadedCount: 0,
    loaded: false,
    // draw: true,
    // activeTab: '',
    activeSlide: 0,
    // groups: []
  }),
  watch: {
    tabIndex() {
      this.$refs.tabs.activeIndex = this.tabIndex;
    },
    managerIndex() {
      this.$refs.manager.activeIndex = this.managerIndex;
    },
    slideIndex() {
      if (this.imageGalleryState[this.slideIndex] !== undefined) {
        const slideTab = this.imageGalleryState[this.slideIndex].category;
        this.tabIndex = this.tabs.indexOf(slideTab);
        this.managerIndex = this.tabs.indexOf(slideTab);
      }
    },
  },
  computed: {
    ...mapState({
      imageGalleryState: (state) => state.imageGallery,
      personsGalleryState: (state) => state.personsGallery,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.slide.forEach((item, i) => {
        const _this = this;
        this.$nextTick(() => {
          let el = item.$el.querySelector("video");
          if (el === null) {
            el = item.$el.querySelector("img");
          }
          el.onload = function () {
            if (!this.complete) {
              return false;
            }
            if (this.naturalWidth === 0) {
              return false;
            }
            _this.loadedCount++;
            if (_this.loadedCount + 1 !== _this.imageGalleryState.length) {
              return;
            }
            console.log(_this.loadedCount, _this.imageGalleryState.length);
            if (_this.loaded) {
              return;
            }
            _this.loaded = true;
            _this.initSwiper();
            setTimeout(() => {
              window.dispatchEvent(new Event("resize"));
            }, 1000);
          };
        });
      });
    });
  },
  methods: {
    renderFraction(currentClass, totalClass) {
      if (currentClass < 9) {
        currentClass = "0" + currentClass;
      }
      return (
        '<div class="image-gallery-block__counter">' +
        '<div class="slide-counter"> ' +
        '<span class="slide-counter__current ' +
        currentClass +
        '"></span>' +
        ' <span class="slide-counter__divider"></span> ' +
        '<span class="slide-counter__maximum ' +
        totalClass +
        '"></span>' +
        "</div>" +
        "</div>"
      );
    },
    initSwiper() {
      this.$nextTick(() => {
        this.getSwiper().init();
      });
    },
    getSwiper() {
      return this.$refs.swiper.$swiper;
    },
    addSlide(slide) {
      this.imageGalleryState.push(slide);
    },
    removeSlide(slide) {
      this.imageGalleryState.splice(this.imageGalleryState.indexOf(slide), 1);
    },
    formatFractionCurrent(number) {
      if (number < 10) {
        number = "0" + number;
      }
      this.activeSlide = number;
      return number;
    },
    handleInit() {
      if (isNaN(this.slideIndex)) {
        this.currentSlide = 0;
      } else {
        this.currentSlide = this.slideIndex;
      }
      this.$refs.swiper.$swiper.update();
    },
    handleTabChange(index) {
      const tab = this.tabs[index];
      for (const slide in this.imageGalleryState) {
        if (this.imageGalleryState[slide].category === tab) {
          this.$nextTick(() => {
            // ToDo - slider bug
            this.$refs.swiper.$swiper.slideToLoop(Number(slide), 1000);
          });
          break;
        }
      }
    },
    slideChange() {
      this.$emit("slide-change");
      this.slideIndex = this.$refs.swiper.$swiper.realIndex;
    },
    goToNext() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slideNext();
    },
    goToPrev() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slidePrev();
    },
  },
};
</script>

<style lang="scss" scoped>
.image-gallery-block {
  background-color: #111111;
  color: #ffffff;
  overflow: hidden;

  &__tabs,
  .gallery-tabs {
    margin-bottom: 3px;
    text-align: center;

    @media screen and (min-width: 375px) {
      text-align: left;
    }

    &__hint {
      color: gray;
      margin-left: 5px;
    }

    .tabs {
      width: auto;
      overflow: auto;

      @media screen and (min-width: 375px) {
        width: auto;
        overflow: hidden;
      }
    }
  }

  &__slide {
    width: auto;

    &-pic,
    &-image {
      max-height: inherit;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__images {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;

    @media screen and (min-width: $screen-sm) {
      margin-left: -15px;
      margin-right: -15px;
    }
    .images-slider__block {
      width: 85vw;
      margin-left: -15px;
      @media screen and (min-width: 360px) {
        margin-left: -60px;
      }
      @media screen and (min-width: $screen-sm) {
        margin-left: -130px;
      }
      @media screen and (min-width: 1400px) {
        width: 80vw;
      }
      @media screen and (min-width: 1600px) {
        width: 60vw;
      }
    }
  }

  &__counter {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;

    &:before {
      content: " ";
      height: 46px;
      width: 1000px;
      right: 0;
      position: absolute;
      background: #000;
      display: block;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    z-index: 1;
    position: relative;
    margin-bottom: 30px;
    font-family: $font-family-rubik;

    @media screen and (min-width: $screen-mini) {
      margin-left: 30px;
    }

    .navigation {
      flex: 1;
      display: none;
      justify-content: flex-end;
      position: relative;
      top: 37px;

      @media screen and (min-width: $screen-sm) {
        display: flex;
      }
    }
  }

  .swiper-container {
    overflow: visible;

    .swiper-pagination-fraction {
      background: #000;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      left: -47vw;
      top: 0;
      bottom: inherit;

      @media screen and (min-width: 475px) {
        left: -55vw;
      }

      @media screen and (min-width: $screen-mini) {
        left: -65vw;
      }

      @media screen and (min-width: $screen-sm) {
        left: -55vw;
      }

      @media screen and (min-width: $screen-md) {
        left: -65vw;
      }

      @media screen and (min-width: $screen-lg) {
        left: -65vw;
      }

      @media screen and (min-width: 1600px) {
        left: -47vw;
      }

      ::v-deep {
        .slide-counter {
          background-color: #000000;
          padding: 7px 27px;
          display: inline-block;
          font-family: $font-family-bebas;
          overflow: hidden;
          position: relative;

          &__current {
            font-weight: bold;
            font-size: 32px;
            position: relative;

            &:after {
              content: " ";
              display: block;
              height: 31px;
              width: 20px;
              transform: rotate(45deg);
              border-left: 1px solid #cccccc;
              position: absolute;
              right: -15px;
              bottom: -10px;
              background-color: #000000;
              z-index: 1;
              box-shadow: 0 0 0 1px #000000;
            }
          }

          &__maximum {
            font-size: 14px;
            opacity: 0.8;
            position: relative;
            z-index: 2;
            top: 7px;
          }
        }
      }

      .slide-counter__current {
        color: red;
      }
    }
  }
  .swiper-slide {
    width: max-content;
    max-height: 165px;

    @media screen and (min-width: $screen-xs) {
      max-height: 220px;
    }

    @media screen and (min-width: $screen-sm) {
      max-height: 410px;
    }
    @media screen and (min-width: $screen-md) {
      max-height: 600px;
    }
  }
}
</style>
