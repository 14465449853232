var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs" }, [
    _c(
      "ul",
      { ref: "list", staticClass: "tabs__list", attrs: { role: "listbox" } },
      _vm._l(_vm.tabs, function (tab, index) {
        return _c(
          "li",
          {
            key: index,
            class: {
              tabs__item: true,
              "tabs__item--active": index === _vm.activeIndex,
            },
            on: {
              click: function ($event) {
                return _vm.handleSelect(index)
              },
            },
          },
          [_vm._v(" " + _vm._s(tab) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }